import "./../css/header.css";

import React, { Component } from "react";
import { StyleSheet } from "react-native";
import { Link } from "react-router-dom";
import Scrollspy from "react-scrollspy";

import { Url } from "./../component/url";

class Header extends Component {
  searchTrigger() {
    document.querySelector("body").classList.add("search-open");
  }
  menuTrigger() {
    document.querySelector("body").classList.add("mobile-menu-open");
  }
  closeMenuTrigger() {
    document.querySelector("body").classList.remove("mobile-menu-open");
  }
  render() {
    const { headerStyle } = this.props;

    var elements = document.querySelectorAll(".has-childmenu > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    const style = {
      backgroundColor: "#273167",
      color: "white",
    };

    const styles2 = StyleSheet.create({
      container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      },
      gradient: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 5,
      },
      button: {
        width: "70%",
        height: 45,
      },
      text: {
        color: "white",
        fontSize: 16,
      },
    });

    return (
      <header className={`header ${headerStyle}`}>
        <div id="mainNav" className="axsis-main-menu-area header--fixed">
          <div className="container">
            <div className="row m-0 align-items-center">
              <div className="col-lg-2 p-0 d-flex justify-content-between align-items-center">
                <div className="logo">
                  <Link className="navbar-brand navbar-brand1" to="/#">
                    <img
                      class="bf-logo"
                      src="assets/images/logo.png"
                      alt="logo"
                    />
                  </Link>
                  <Link className="navbar-brand navbar-brand2" to="/#">
                    <img
                      class="bf-logo"
                      src="assets/images/footer-logo.png"
                      alt="logo"
                    />
                  </Link>
                </div>
                <div className="menu-bar d-lg-none" onClick={this.menuTrigger}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
              <div className="op-mobile-menu col-lg-10 p-0 d-lg-flex align-items-center justify-content-end">
                <div className="m-menu-header d-flex justify-content-between align-items-center d-lg-none">
                  <Link to="/#" className="logo">
                    <img
                      class="bf-logo"
                      src="assets/images/logo.png"
                      alt="logo"
                    />
                  </Link>
                  <span
                    className="close-button"
                    onClick={this.closeMenuTrigger}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                </div>
                <Scrollspy
                  className="custom-border-top nav-menu d-lg-flex flex-wrap list-unstyled mr_lg--50  mb-0 justify-content-center"
                  items={[
                    "banner",
                    "feature",
                    "screenshot",
                    //"video-notmenu",
                    "pricing",
                    "faq",
                    "contact",
                  ]}
                  currentClassName="is-current"
                  offset={-200}
                >
                  <li>
                    <a href="/#banner" onClick={this.closeMenuTrigger}>
                      <span>Inicio</span>
                    </a>
                  </li>
                  <li>
                    <a href="/#feature" onClick={this.closeMenuTrigger}>
                      <span>Misiones</span>
                    </a>
                  </li>
                  <li>
                    <a href="/#screenshot" onClick={this.closeMenuTrigger}>
                      <span>Uso</span>
                    </a>
                  </li>
                  <li>
                    <a href="/#crm" onClick={this.closeMenuTrigger}>
                      <span>CRM</span>
                    </a>
                  </li>
                  {/*<li><a href="/#video-notmenu" onClick={this.closeMenuTrigger}><span>Video</span></a></li>*/}
                  <li>
                    <a href="/#pricing" onClick={this.closeMenuTrigger}>
                      <span>Precios</span>
                    </a>
                  </li>
                  <li>
                    <a href="/#faq" onClick={this.closeMenuTrigger}>
                      <span>Preguntas frecuentes</span>
                    </a>
                  </li>
                  <li>
                    <a href="/#contact" onClick={this.closeMenuTrigger}>
                      <span>Contacto</span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="principal-color btn-login"
                      href={Url.signinUrl}
                      onClick={this.closeMenuTrigger}
                      target="_blank"
                    >
                      <span>Iniciar sesión</span>
                    </a>
                  </li>
                </Scrollspy>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default Header;
