import React, { Component } from "react";
import SectionHeader from "../component/section-header";
import ContactForm3 from "./../component/contact-form3";

// header section content
const title = <h3>Llena el formulario y nos llegará un WhatsApp</h3>;
const alignment = "section-header text-center pb--60";

class ContactSection extends Component {
  render() {
    return (
      <section id="contact" className="contact-section">
        <div className="container">
          <div className="contact-wrapper">
            <SectionHeader title={title} alignment={alignment} />
            <div className="contact">
              <ContactForm3 />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ContactSection;
