import React, { Component } from "react";
import {
  FaArrowRight,
  FaFacebook,
  FaHome,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "./../css/footer.css";

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="footer-top pt--100 pt_sm--120 pb--40 pb_lg--90">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-6">
                <div className="footer-widget text-center text-lg-left mb--30 mb-lg-0">
                  <Link to="/" className="footer-logo">
                    <img src="assets/images/footer-logo.png" alt="logo" />
                  </Link>
                  <ul className="footer-contact-info pl-0 list-unstyled">
                    <li>
                      <a
                        href="mailto:info@notmenu.com?Subject=Quiero%20información"
                        target="_top"
                      >
                        <strong>Email:</strong> info@notmenu.com
                      </a>
                    </li>
                    <li>
                      <a href="tel:5524998882">
                        <strong>Teléfono:</strong> 5524998882
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 pl-lg-6">
                <div className="footer-widget text-center text-lg-left mb--30 mb-lg-0">
                  <h5 className="footer-title">Redes sociales</h5>
                  <ul className="list-ustyled pl-0 pb-0 list-unstyled">
                    <li>
                      <FaHome /> <Link to="/">Home</Link>
                    </li>
                    <li>
                      <FaLinkedin />{" "}
                      <a
                        href="https://www.linkedin.com/company/notmenu"
                        target="_blank"
                      >
                        Linkedin
                      </a>
                    </li>
                    <li>
                      <FaFacebook />{" "}
                      <a
                        href="https://www.facebook.com/notmenu"
                        target="_blank"
                      >
                        Facebook
                      </a>
                    </li>
                    <li>
                      <FaInstagram />{" "}
                      <a
                        href="https://www.instagram.com/notmenu.mx"
                        target="_blank"
                      >
                        Instagram
                      </a>
                    </li>
                    <li>
                      <FaTwitter />{" "}
                      <a href="https://twitter.com/_notmenu" target="_blank">
                        Twitter
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <p>
                &copy; Copyrights 2024 <Link to="/">NotMenu</Link> All rights
                reserved.
              </p>
              <p>
                From
                <a
                  href="#"
                >
                  {" "}
                  binaryfractal ®
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
