import { Component, React } from 'react';

import { Url } from './../component/url';

export const RedirectKey = ({match}) => {
    window.location = `https://${Url.menuUrl}/${match.params.key}`;
}

export const RedirectId = ({match}) => {
    window.location = `https://${Url.menuUrl}/qr/${match.params.id}`;
}