export class Url {
    static test = false;
    static domain = 'notmenu.app';
    static adminUrl = this.test ? `dev.admin.${this.domain}` : `admin.${this.domain}`;
    
    static signupUrl = `https://${this.adminUrl}/auth/signup`;
    static signinUrl = `https://${this.adminUrl}/auth/signin`;

    static menuUrl = this.test ? `dev.${this.domain}` : `${this.domain}`;
    static demoUrl = this.test ? `https://${this.menuUrl}/LosChilaquiles` : `https://${this.menuUrl}/chilindrin`;
    static contactForm = 'https://us-central1-shimenu-binaryfractal-dev.cloudfunctions.net/general/api/contacts';
    static googlePlay = 'https://play.google.com/store/apps/details?id=com.binaryfractal.mmnn';
    static appleStore = 'https://play.google.com/store/apps/details?id=com.binaryfractal.mmnn';
}