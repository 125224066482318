import React, { Component } from 'react';
import SectionHeader from './../component/section-header';
import InterfaceSlider from './../component/interface-slider';

const title = <h3 className='mb--20'>CRM</h3>;
const subtitle = (
  <h6>
    Datos en tus Manos: Transforma Clientes en Fans con Campañas Personalizadas
  </h6>
);
const alignment = 'section-header text-center';
const slides = [
  {
    imagUrl: 'assets/images/crm-ss/crm-dashboard.jpeg',
    imageAlt: 'crm-dashboard',
  },
  {
    imagUrl: 'assets/images/crm-ss/crm-clients.jpeg',
    imageAlt: 'crm-dashboard',
  },
  {
    imagUrl: 'assets/images/crm-ss/crm-add-template.jpeg',
    imageAlt: 'crm-dashboard',
  },
];
const breakpoints = {
  990: {
    slidesPerView: 2,
    spaceBetween: 0,
  },
  450: {
    slidesPerView: 1,
    spaceBetween: 0,
  },
};
const customClass = 'desktop-ss';
const FeatureList = [
  {
    title: 'Comunicación Eficiente',
    description:
      'Con nuestro CRM, conecta con tus clientes de manera instantánea vía WhatsApp, agilizando pedidos y promociones.',
  },
  {
    title: 'Fidelización Garantizada',
    description:
      'Haz que tus clientes se sientan especiales en cada visita con experiencias personalizadas, asegurando su lealtad.',
  },
  {
    title: 'Decisiones Inteligentes',
    description:
      'Convierte datos en estrategias ganadoras, mejorando la experiencia del cliente y aumentando tus ingresos.',
  },
];

class CRMSection extends Component {
  render() {
    return (
      <section
        id='crm'
        className='interface-slider-section pt--60 pb--50 pt_lg--120'
      >
        <SectionHeader
          title={title}
          subtitle={subtitle}
          alignment={alignment}
        />

        <div className='container mt-5'>
          <div className='row justify-content-center justify-content-lg-start'>
            {FeatureList.map((val, i) => (
              <div className='col-md-6 col-lg-4 mb--30 mb-lg-0' key={i}>
                <div className='feature-item text-left'>
                  <h5>{val.title}</h5>
                  <p>{val.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className='interface-slider mt-5'>
          <InterfaceSlider
            slides={slides}
            breakpoints={breakpoints}
            customClass={customClass}
          ></InterfaceSlider>
        </div>
      </section>
    );
  }
}

export default CRMSection;
