import React, { Component } from "react";
import { Swiper, Pagination } from "swiper/js/swiper.esm";
import ReactIdSwiperCustom from "react-id-swiper/lib/ReactIdSwiper.custom";
import "./../css/component/interface-slider.css";
import "swiper/css/swiper.css";
const params = {
  // Provide Swiper class as props
  Swiper,
  containerClass: "interface-slider-container swiper-container-initialized swiper-container-horizontal",
  slidesPerView: 1,
  spaceBetween: 50,
  loop: true,
  initialSlide: 0,
  keyboardControl: true,
  mousewheelControl: true,
  preventClicks: true,
  preventClicksPropagation: true,
  centeredSlides: true,
  // Add modules you need
  modules: [Pagination],
  pagination: {
    el: ".swiper-pagination",
    type: "bullets",
    clickable: true,
  },
  breakpoints: {
    990: {
      slidesPerView: 5,
      spaceBetween: 0,
    },
    450: {
      slidesPerView: 3,
      spaceBetween: 0,
    },
  },
};

const infterFaceSliderList = [
  {
    imagUrl: "assets/images/interface-slider/01.jpg",
    imageAlt: "screenshot1",
  },
  {
    imagUrl: "assets/images/interface-slider/02.jpg",
    imageAlt: "screenshot2",
  },
  {
    imagUrl: "assets/images/interface-slider/03.jpg",
    imageAlt: "screenshot3",
  },
  {
    imagUrl: "assets/images/interface-slider/04.jpg",
    imageAlt: "screenshot4",
  },
  {
    imagUrl: "assets/images/interface-slider/05.jpg",
    imageAlt: "screenshot5",
  },
  {
    imagUrl: "assets/images/interface-slider/06.jpg",
    imageAlt: "screenshot6",
  },
  {
    imagUrl: "assets/images/interface-slider/07.jpg",
    imageAlt: "screenshot7",
  },
];

class InterfaceSlider extends Component {
  render() {
    const { slides, breakpoints, customClass } = this.props;
    const slidesData = slides || infterFaceSliderList;
    params.breakpoints = breakpoints || params.breakpoints;
    const newParams = {...params};

    if (customClass !== undefined) {
      if (!newParams.containerClass.includes(customClass)) {
        newParams.containerClass += ` ${customClass}`;
      }
    }
    console.info('newParams.containerClass', newParams.containerClass)

    return (
      <ReactIdSwiperCustom {...newParams}>
        {slidesData.map((val, i) => (
          <div className='swiper-slide' key={i}>
            <img src={`${val.imagUrl}`} alt={`${val.imageAlt}`} />
          </div>
        ))}
      </ReactIdSwiperCustom>
    );
  }
}

export default InterfaceSlider;
