import React, { Component } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { FaPlus, FaMinus } from "react-icons/fa";
import './../css/component/accordion.css';

const FaqList = [
    {
        question: '¿NotMenu es apto para mí negocio?',
        answer: 'Sí, NotMenu se adapta para cualquier tipo de negocio, algunos ejemplos son: puestos de comida, cafeterías, restaurantes con o sin meseros, negocios con modalidad para recoger y/o a domicilio, ventas por internet, etc...'
    },
    {
        question: '¿Es fácil utilizarlo?',
        answer: 'Es muy fácil, no necesitas conocimientos avanzados para comenzar a crear tu menú, inclusive tus clientes con sólo escanear tu QR o mandarles tu dirección de internet única podrán ver tu menú y hacerte un pedido de inmediato.'
    },
    {
        question: '¿Puedo crear promociones?',
        answer: 'Sí puedes crear todas las promociones que tu quieras sin limite y sin costo adicional. Estas las administras como categorías.'
    },
    {
        question: '¿Por qué usar NotMenu y no la competencia?',
        answer: 'NotMenu no es una simple imagen dificil de leer, no necesita descargar nada, no limita a tus clientes los permite interactuar, tienes tu panel de administrador siempre disponible y cambios ilimitados sin costo.'
    },
    {
        question: '¿Qué es la dirección de internet única?',
        answer: 'Es una dirección de internet creada especial para tu negocio como si tuvieras tu propia página de internet con sistema de pedidos.'
    },
    {
        question: '¿Puedo modificar yo el menú?',
        answer: '¡Claro!, con NotMenu tienes tu propio panel de administrador agregar, modificar o quitar productos en cualquier momento, sin comisiones y en tiempo real.'
    },
    {
        question: '¿Tiene un plazo forzoso o necesito poner mi tarjeta?',
        answer: 'NotMenu no pedirá tu tarjeta, tampoco tiene plazos forzosos, aprovecha tu periodo gratis y si no te gusta cancela cuando quieras sin costo.'
    },
    {
        question: '¿Tiene programa de referidos?',
        answer: 'Por cada negocio que se registre y lo utilice durante al menos 1 mes tu recibes también 1 mes adicional de servicio gratis.'
    }
]


class Accordion1 extends Component {
    
    render() {
        return (
            <Accordion preExpanded={'0'}>
                {FaqList.map((val, i)=>(
                    <AccordionItem className="faq" key={i}>
                        <AccordionItemHeading className="faq-header">
                            <AccordionItemButton className="btn btn-link">
                                <span className="icon">
                                    <FaPlus className="fa-plus" />
                                    <FaMinus className="fa-minus" />
                                </span>
                                <span className="text">{val.question}</span>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='faq-body'>
                            <p>
                                {val.answer}
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        )
    }
}

export default Accordion1;